.site-plan{
	margin: 75px 0 0;
  @media screen and (max-width: $phone){
    margin: 0;
  }
	.is-title{
		@include min();
		color: darken($orange, 48%);
		background: lighten(#efcda2, 16%);
		padding: 56px 0;
		font-size: .88rem;
		line-height: 1;
		text-align: center;
    @media screen and (max-width: $phone){
      padding: 112px 0 48px;
    }
		h1{
			margin: 0 0 32px;
			opacity: .92;
			letter-spacing: .24em;
      @media screen and (max-width: $phone){
        font-size: .8rem;
        margin: 0 0 16px;
      }
		}
		svg{
			width: 224px;
			margin: 0 auto;
			display: block;
      @media screen and (max-width: $phone){
        width: 50%;
      }
			path{
				fill: darken($orange, 48%);
			}
		}
	}
	.planlist{
		@include flex();
		margin: 0 0 0 -40px;
		padding-bottom: 32px;
		justify-content: flex-start;
    @media screen and (max-width: $phone){
      display: block;
      margin: 0;
    }
		img{
			width: 100%;
			height: 100%;
			object-fit: cover;
			object-position: center;
		}
		&__item{
			width: 33.3%;
			padding: 0 0 0 40px;
			margin: 72px 0 0;
      @media screen and (max-width: $tab){
        width: 50%;
      }
      @media screen and (max-width: $phone){
        width: auto;
        padding: 0;
        margin: 0 0 48px;
        &:last-child{
          margin-bottom: 0;
        }
      }
			&:nth-child(-n+3){
        @media screen and (min-width: calc(#{$tab} + 1px)){
				  margin-top: 0;
        }
        @media screen and (max-width: $tab){
          margin-top: 72px;
        }
        @media screen and (max-width: $phone){
          margin-top: 0;
        }
			}
      &:nth-child(-n+2){
        @media screen and (max-width: $tab){
          margin-top: 0;
        }
      }
			h2{
				text-align: center;
				@include min();
				font-size: .92rem;
				line-height: 1.56;
				margin: 0 0 16px;
        @media screen and (max-width: $phone){
          text-align: left;
        }
				strong{
					font-weight: 700;
					color: $hard;
					display: inline-block;
					margin: 0 4px;
					font-size: 1.44rem;
				}
			}
			&__main{
				width: 100%;
				padding: calc(100% / 16 * 10) 0 0;
				position: relative;
				border: $border 1px solid;
				margin: 0 0 16px;
				img{
					position: absolute;
					top: 0;
					left: 0;
				}
			}
			&__pics{
				@include flex();
				margin: 0 0 0 -12px;
				&__pic{
					padding: 0 0 0 12px;
					width: 33.3%;
					height: 64px;
					a{
						display: block;
					}
					img{
						object-fit: contain;
						object-position: center;
						background: $gray;
					}
				}
			}
			dl{
				margin: 16px 0 0;
				padding: 16px 0 0;
				border-top: $border 1px solid;
				@include flex();
				dt{
					width: 100%;
					margin: 0 0 12px;
					a{
						background: $gray;
						color: $normal;
						text-align: center;
						display: block;
						font-size: 1.08rem;
						padding: 12px 0;
						border-radius: 4px;
						position: relative;
						&:hover{
							background: $hard;
							color: #FFF;
							i{
								color: #FFF;
							}
						}
						i{
							position: absolute;
							top: 50%;
							right: 20px;
							transform: translateY(-50%);
							font-size: .92rem;
							color: $hard;
						}
					}
				}
				dd{
					width: 50%;
					font-size: .92rem;
					&:before{
						content: attr(data-eng);
						width: 80px;
						background: $gray;
						border-radius: 2px;
						padding: 6px 0;
						text-align: center;
						display: inline-block;
						font-size: .76rem;
						margin: 0 8px 0 0;
					}
				}
			}
		}
	}
}