.site-works{
	.cate{
		padding-bottom: 56px;
		padding-left: $side;
		padding-right: $side;
    @media screen and (max-width: $phone){
      padding-bottom: 32px;
      padding-left: $sp-side;
      padding-right: $sp-side;
    }
		&__title{
			text-align: center;
			font-size: 1.44rem;
			font-weight: 700;
			line-height: 1;
			margin: 0 0 28px;
			i{
				display: inline-block;
				vertical-align: middle;
				margin: 0 8px 0 0;
				color: $hard;
			}
		}
		&__list{
			@include flex();
			justify-content: flex-start;
			margin: 0 0 0 -4px;
			li{
				width: 20%;
				padding: 0 0 0 4px;
				margin: 4px 0 0;
        @media screen and (max-width: $tab){
          width: 33.3%;
        }
        @media screen and (max-width: $phone){
          width: 50%;
        }
				a{
					display: block;
					padding: 12px 20px;
					text-align: center;
					position: relative;
					z-index: 1;
					transition: color 200ms $ease;
					&:before,
					&:after{
						content: '';
						width: 100%;
						height: 100%;
						position: absolute;
						top: 0;
						left: 0;
						background: $gray;
						z-index: -1;
					}
					&:after{
						left: auto;
						right: 0;
						width: 0;
						background: $hard;
						transition: width 300ms $ease;
					}
					&:hover{
						color: #FFF;
						&:after{
							left: 0;
							right: auto;
							width: 100%;
						}
					}
				}
			}
		}
	}
	.works{
		padding-top: 24px;
		padding-left: $side;
		padding-right: $side;
		padding-bottom: 96px;
		border-bottom: #FFF 4px solid;
		@include tex();
		position: relative;
    @media screen and (max-width: $phone){
      padding-top: 24px;
      padding-left: $sp-side;
      padding-right: $sp-side;
      padding-bottom: 48px;  
    }
		&:before{
			content: '';
			width: 0;
			height: 0;
			border-style: solid;
			border-width: 24px 32px 0 32px;
			border-color: #FFF transparent transparent transparent;
			position: absolute;
			left: 50%;
			top: 0;
			transform: translateX(-50%);
		}
		
		&__list{
			@include flex();
      justify-content: flex-start;
			margin: 0 0 0 -32px;
      @media screen and (max-width: $tab){
        margin: 0 0 0 -24px;
      }
      @media screen and (max-width: $phone){
        margin: 0;
        display: block;
      }
			> li{
				width: 33.3%;
				padding: 0 0 0 32px;
				margin: 48px 0 0;
        @media screen and (max-width: $tab){
          width: 50%;
          padding: 0 0 0 24px;
          margin: 24px 0 0;
        }
        @media screen and (max-width: $phone){
          padding: 0 0 0 8px;
          width: auto;
        }
				a{
					display: block;
					background: #FFF;
					border-radius: 6px;
					overflow: hidden;
					box-shadow: 4px 4px 32px rgba($normal,.08);
				}
				figure{
					width: 100%;
					padding: calc(100% / 16 * 10) 0 0;
					position: relative;
					img{
						width: 100%;
						height: 100%;
						position: absolute;
						object-fit: cover;
						object-position: center;
						top: 0;
						left: 0;
					}
				}
				dl{
					padding: 20px;
					line-height: 1.6;
					padding-right: 40px;
					position: relative;
					&:after{
						content: "\f054";
						font-family: "Font Awesome 5 Free";
						font-weight: 900;
						position: absolute;
						top: 50%;
						right: 20px;
						font-size: .72rem;
						color: $hard;
						transform: translateY(-50%);
					}
					dt{
						font-size: 1rem;
						font-weight: 700;
					}
					dd{
						font-size: .92rem;
					}
				}
			}
		}
	}
}

.site-works-single{
	margin: 75px 0 0;
  @media screen and (max-width: $phone){
    margin: 0;
  }
	.wrap.mid.mid-top{
		padding-top: 72px;
    @media screen and (max-width: $phone){
      padding-top: 96px;
    }
	}
	.display{
		&.min{
			max-width: 980px;
		}
	}
	.worktitle{
		padding: 0 200px 0 0;
		position: relative;
    @media screen and (max-width: $phone){
      padding: 0;
    }
		h1{
			font-size: 2rem;
			font-weight: 700;
			line-height: 1.56;
      @media screen and (max-width: $phone){
        font-size: 1.56rem;
        text-align: center;
      }
		}
		.category{
			width: 160px;
			padding: 8px 16px;
			text-align: center;
			font-size: .92rem;
			position: absolute;
			top: 50%;
			right: 0;
			transform: translateY(-50%);
			background: $normal;
			color: #FFF;
			border-radius: 999px;
      @media screen and (max-width: $phone){
        position: relative;
        top: 0;
        transform: translate(0,0);
        margin: 12px auto 0;
        width: auto;
      }
		}
	}
	
	.workslides{
		margin: 48px 0 72px;
    @media screen and (max-width: $phone){
      margin: 24px 0;
    }
		&.single{
			@include tex();
			.swiper-wrapper{
				@include flex();
				justify-content: center;
				background: rgba(#000,.04);
				figure{
					width: 980px;
          @media screen and (max-width: $tab){
            width: 100%;
          }
				}
			}
		}
		figure{
			width: 640px;
			height: calc(640px / 16 * 10);
      @media screen and (max-width: $phone){
        width: 100vw;
        height: calc(100vw / 16 * 10);
      }
			img{
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
		}
	}
	.detail{
		&__title{
			font-size: 1.72rem;
			font-weight: 700;
			text-align: center;
			line-height: 1.56;
			margin: 0 0 24px;
      @media screen and (max-width: $phone){
        font-size: 1.32rem;
        margin: 0 15px 24px;
      }
			&:before{
				content: 'description';
				text-transform: uppercase;
				text-align: center;
				color: $hard;
				display: block;
				font-size: .72rem;
				letter-spacing: .24em;
				margin: 0 0 8px;
			}
		}
		&__text{
			@include tex();
			padding: 56px;
			font-size: 1.08rem;
			line-height: 2;
			margin: 0 0 56px;
      @media screen and (max-width: $phone){
        padding: 24px;
      }
		}
	}
}