.site-staff{
	margin-top: 75px;
	.site-module.wrap.mid{
		padding-top: 72px;
    @media screen and (max-width: $phone){
      padding-top: 32px;
    }
	}
	.stafflist{
		@include flex();
		justify-content: flex-start;
		margin: 48px 0 0 -40px;
		padding-bottom: 32px;
    @media screen and (max-width: $phone){
      display: block;
      margin: 0;
    }
		li{
			padding: 0 0 0 40px;
			width: 25%;
			margin: 56px 0 0;
      @media screen and (max-width: $phone){
        width: auto;
        padding: 0;
        margin: 0 0 12px;
        &:last-child{
          margin-bottom: 0;
        }
      }
			&:nth-child(-n+4){
				margin-top: 0;
			}
			.box{
				cursor: pointer;
        @media screen and (max-width: $phone){
          @include flex();
          align-items: center;
          background: $gray;
          padding: 12px 16px;
          border-radius: 4px;
        }
				&:hover{
					figure{
						&:before{
							border-width: 20px;
              @media screen and (max-width: $phone){
                border-width: 0;
              }
						}
						&:after{
							transform: scale(1.1);
						}
					}
				}
			}
			figure{
				width: 100%;
				padding: 100% 0 0;
				position: relative;
        @media screen and (max-width: $phone){
          width: 56px;
          height: 56px;
          padding: 0;
        }
				&:before{
					content: '';
					width: 100%;
					height: 100%;
					border: $gray 8px solid;
					position: absolute;
					top: 0;
					left: 0;
					border-radius: 50%;
					transform: scale(1.01);
					z-index: 2;
					transition: border 300ms $ease;
          @media screen and (max-width: $phone){
            display: none;
          }
				}
				&:after{
					content: '';
					width: 40px;
					height: 44px;
					background: url(../../../images/about/icon_reaf_2x.png) no-repeat center / 40px;
					position: absolute;
					top: 12px;
					right: 12px;
					border-radius: 999px;
					z-index: 3;
					transition: transform 300ms $ease;
          @media screen and (max-width: $phone){
            display: none;
          }
				}
				img{
					width: 100%;
					height: 100%;
					object-fit: cover;
					object-position: center;
					position: absolute;
					border-radius: 50%;
					top: 0;
					left: 0;
				}
			}
			dl{
				text-align: center;
				line-height: 1.45;
				margin: 24px 0 0;
				position: relative;
        @media screen and (max-width: $phone){
          width: calc(100% - 56px);
          padding: 0 0 0 16px;
          margin: 0;
          text-align: left;
          @include flex();
          align-items: center;
          justify-content: flex-start;
        }
				&:before{
					content: '';
					width: 0;
					height: 0;
					border-style: solid;
					border-width: 0 8px 10px 8px;
					border-color: transparent transparent darken($gray, 1%) transparent;
					position: absolute;
					top: 0;
					left: 50%;
					transform: translate(-50%, -100%);
          @media screen and (max-width: $phone){
            display: none;
          }
				}
				dt{
					width: 80%;
					font-size: .92rem;
					background: darken($gray, 1%);
					border-radius: 999px;
					text-align: center;
					padding: 6px 0;
					margin: 0 auto 12px;
          @media screen and (max-width: $phone){
            background: #FFF;
            width: auto;
            text-align: left;
            padding: 8px 16px;
            display: inline-block;
            margin: 0;
          }
				}
				dd{
					@include min();
					font-size: 1.24rem;
					font-weight: 700;
          @media screen and (max-width: $phone){
            margin: 0 0 0 16px;
            font-size: 1.2rem;
          }
				}
			}
		}
	}
	
	.overlay{
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: rgba(#222,.92);
		visibility: hidden;
		z-index: 9999;
		&.active{
			visibility: visible;
		}
		.box{
			width: 560px;
			padding: 32px;
			background: #FFF;
			box-shadow: 0 0 64px rgba(#000,.32);
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			border-radius: 8px;
			opacity: 0;
      @media screen and (max-width: $phone){
        width: 90vw;
        max-height: 90vh;
        padding: 16px;
      }
			&.active{
				animation: views 400ms 400ms $ease forwards;
				@keyframes views{
					0%{
						opacity: 0;
						transform: translate(-50%, -50%) scale(.9);
					}
					100%{
						opacity: 1;
						transform: translate(-50%, -50%) scale(1);
					}
				}
			}
			&__close{
				width: 44px;
				height: 44px;
				border-radius: 50%;
				@include flex();
				align-items: center;
				justify-content: center;
				background: #000;
				position: absolute;
				top: 0;
				right: 0;
				transform: translate(50%, -50%);
				cursor: pointer;
				transition: transform 200ms $ease;
        @media screen and (max-width: $phone){
          top: 12px;
          right: 12px;
        }
				&:hover{
					transform: translate(50%, -50%) scale(1.2);
				}
				svg{
					width: 12px;
					path{
						fill: #FFF;
					}
				}
			}
			&__inner{
				width: 100%;
				height: auto;
				overflow: auto;
				max-height: calc(84vh - 64px);
				&__pic{
					border-radius: 4px;
					margin: 0 auto;
					overflow: hidden;
					img{
						width: 100%;
						height: 100%;
						object-fit: cover;
					}
				}
				&__name{
					@include flex();
					align-items: center;
					justify-content: flex-start;
					margin: 24px 0;
					dt{
						background: darken($gray, 1%);
						padding: 5px 24px;
						border-radius: 999px;
						font-size: .92rem;
						margin: 0 20px 0 0;
					}
					dd{
						@include min();
						font-size: 1.24rem;
						font-weight: 700;
					}
				}
				&__table{
					border-radius: 4px;
					border: $border 1px solid;
					dl{
						@include flex();
						border-bottom: $border 1px solid;
						&:last-of-type{
							border-bottom: 0;
						}
						dt,dd{
							padding: 15px;
						}
						dt{
							width: 120px;
							background: $gray;
						}
						dd{
							width: calc(100% - 120px);
						}
					}
				}
				&__title{
					font-size: 1.32rem;
					font-weight: 700;
					margin: 56px 0 16px;
					padding: 0 0 16px;
					line-height: 1;
					border-bottom: $border 1px solid;
					position: relative;
					&:before{
						content: '';
						width: 64px;
						height: 2px;
						background: $hard;
						position: absolute;
						left: 0;
						bottom: -1px;
					}
				}
				&__list{
					li{
						margin: 0 0 12px;
						&:last-child{
							margin: 0;
						}
						a{
							padding: 8px;
							@include flex();
							align-items: center;
							background: darken($gray, 2%);
							color: $normal;
							border-radius: 999px;
							padding-right: 48px;
							position: relative;
							&:hover{
								img{
									opacity: 1;
								}
								svg{
									right: 20px;
								}
							}
							img{
								width: 48px;
								height: 48px;
								border-radius: 50%;
								object-fit: cover;
								object-position: center;
							}
							p{
								width: calc(100% - 48px);
								padding: 0 0 0 24px;
							}
							svg{
								position: absolute;
								top: 50%;
								right: 24px;
								transform: translateY(-50%);
								width: 8px;
								transition: right 200ms $ease;
								path{
									fill: $normal;
								}
							}
						}
					}
				}
			}
		}
	}
	.fade-enter-active, .fade-leave-active {
		transition: opacity 0.5s;
	}

	.fade-enter, .fade-leave-to {
		opacity: 0;
	}
}