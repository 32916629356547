.site-index{
	.concept{
		position: relative;
		&:before{
			content: '';
			width: 64%;
			position: absolute;
			top: 60px;
			right: -$side;
			height: 100%;
			@include tex();
			@media screen and (max-width: $phone){
				width: 96%;
				right: -$sp-side;
				top: 40px;
			}
		}
		&__bg{
			position: absolute;
			top: 0;
			left: -$side;
			width: 72%;
			height: 100%;
			@media screen and (max-width: $phone){
				position: relative;
				left:  -$sp-side;
				width: 96%;
				height: 56vw;
			}
			img{
				width: 100%;
				height: 100%;
				object-fit: cover;
				object-position: center;
			}
		}
		&__text{
			position: relative;
			margin: 0 0 0 56%;
			padding: 120px 0;
			width: 44%;
			z-index: 2;
			@media screen and (max-width: $tab){
				margin: 0 0 0 40%;
				width: 60%;
				padding: 64px 0;
			}
			@media screen and (max-width: $phone){
				width: auto;
				margin: 24px 0 0;
				padding: 0;
			}
			dl{
				max-width: 580px;
				padding: 64px 56px;
				background: #FFF;
				@media screen and (max-width: $tab){
					padding: 48px 40px;	
				}
				@media screen and (max-width: $phone){
					padding: 32px $sp-side;	
				}
				dt{
					@include min();
					font-size: 1.96rem;
					letter-spacing: .08em;
					line-height: 1.44;
					font-weight: 700;
					margin: 0 0 12px;
					transform: translateX(-.1em);
					@media screen and (max-width: $phone){
						font-size: 1.32rem;
						text-align: center;
					}
					&:before{
						content: attr(data-eng);
						color: $hard;
						display: block;
						line-height: 1;
						font-size: .8rem;
						margin: 0 0 16px;
						letter-spacing: .24em;
					}
				}
				dd{
					font-size: 1.04rem;
					line-height: 1.88;
					text-align: justify;
					@media screen and (max-width: $phone){
						font-size: .96rem;
						line-height: 1.66;
					}
				}
			}
		}
	}
	
	.advantage{
		&.wrap{
			&.mid{
				padding-right: 6.6vw;
				padding-left: 6.6vw;
				position: relative;
			}
			&:after{
				content: '';
				width: 100%;
				height: calc(100% - 300px);
				position: absolute;
				top: 300px;
				left: 0;
				@include tex();
				z-index: -1;
				@media screen and (max-width: $phone){
					display: none;
				}
			}
		}
		.ill{
			position: absolute;
			top: -210px;
			right: 0;
			width: 360px;
			height: 360px;
			@include ami(rgba(darken($hard, 0%),.99));
			background-size: 8px;
			&.bm{
				width: 280px;
				height: 280px;
				top: auto;
				right: auto;
				bottom: 160px;
				left: 0;
			}
			@media screen and (max-width: $phone){
				display: none;
			}
		}
		h2{
			@include min();
			font-size: 2rem;
			font-weight: 700;
			padding: 48px 0 0;
			margin: 0 0 20px;
			transform: translateX(-2px);
			text-align: left;
			@media screen and (max-width: $phone){
				font-size: 1.44rem;
				margin: 0 0 16px;
			}
			&:after{
				content: attr(data-eng);
				display: inline-block;
				margin: 0 0 0 24px;
				font-size: 1.12rem;
				color: $hard;
				@media screen and (max-width: $phone){
					font-size: .88rem;
					margin: 0 0 0 12px;
				}
			}
		}
		&__list{
			@include flex();
			margin: 0 0 0 -3.3vw;
			position: relative;
			z-index: 2;
			@media screen and (max-width: $tab){
				margin: 0 0 0 -2vw;	
			}
			@media screen and (max-width: $phone){
				display: block;
				margin: 0;
			}
			&__item{
				width: 33.3%;
				padding: 0 0 0 3.3vw;
				@media screen and (max-width: $tab){
					padding: 0 0 0 2vw;	
				}
				@media screen and (max-width: $phone){
					width: auto;
					padding: 0;
					margin: 0 0 20px;
					&:last-child{
						margin: 0;
					}
				}
				@media screen and (min-width: calc(#{$phone} + 1px)){
					@for $i from 1 through 3{
						&:nth-child(#{$i}){
							margin-top: ($i - 1) * 40px;
						}
					}
				}
				&__box{
					background: #FFF;
					height: auto;
					border-radius: 4px;
					overflow: hidden;
					box-shadow: 8px 8px 16px rgba($normal,.04), 0 0 32px rgba($normal,.06);
				}
				figure{
					img{
						width: 100%;
					}
				}
				dl{
					text-align: center;
					padding: 24px;
					padding-top: 20px;
					@media screen and (max-width: $tab){
						padding: 15px;	
					}
					@media screen and (max-width: $phone){
						padding: 16px $sp-side;	
					}
					dt{
						@include min();
						font-size: 1.44rem;
						font-weight: 700;
						padding: 12px 0 0;
						background: url("/images/common/business_icon_h_2x.png") no-repeat center top / 33px;
						@media screen and (max-width: $tab){
							font-size: 1.2rem;
						}
					}
					dd{
						font-size: 1.02rem;
						max-width: 340px;
						margin: 8px auto 0;
						padding: 12px 0 0;
						border-top: $border 1px solid;
						@media screen and (max-width: $tab){
							font-size: .96rem;	
						}
					}
				}
			}
		}
		
		// 追加
		.parse{
			background: #FFF;
			padding: 40px;
			position: relative;
			box-shadow: 8px 8px 16px rgba($normal,.04), 0 0 32px rgba($normal,.06);
			z-index: 2;
			max-width: 1400px;
			margin: 64px auto 0;
			border-radius: 4px;
			@include flex();
			align-items: center;
      @media screen and (max-width: $tab){
        display: block;
      }
      @media screen and (max-width: $phone){
        margin: 24px 0 0;
        padding: 16px;
      }
			&__badge{
				position: absolute;
				top: 0;
				left: 0;
				background: $hard;
				width: 80px;
				height: 80px;
				@include flex();
				align-items: center;
				justify-content: center;
				color: #FFF;
				@include min();
				font-size: 1.72rem;
				line-height: 1;
        @media screen and (max-width: $phone){
          width: 56px;
          height: 56px;
          font-size: 1.4rem;
        }
				&__d{
				}
			}
			&__pic{
				width: 480px;
        @media screen and (max-width: $tab){
          width: 100%;
        }
				img{
					width: 100%;
				}
			}
			&__title{
				width: calc(100% - 480px);
				padding: 0 0 0 48px;
        @media screen and (max-width: $tab){
          width: 100%;
          padding: 32px 0 0;
        }
				&__h{
					@include min();
					font-size: 1.72rem;
					font-weight: 700;
					line-height: 1.5;
					margin: 0 0 24px;
					padding: 0 0 24px;
					border-bottom: $border 1px solid;
          @media screen and (max-width: $phone){
            font-size: 1.24rem;
          }
				}
				&__data{
					font-size: 1.04rem;
          @media screen and (max-width: $tab){
            br{
              display: none;
            }
          }
				}
				&__pic{
					@include flex();
					align-items: center;
					justify-content: flex-start;
					background: $gray;
					padding: 16px 24px;
					margin: 24px 0 0;
          @media screen and (max-width: $tab){
            display: block;
          }
          @media screen and (max-width: $phone){
            padding: 16px;
          }
					figcaption{
						font-size: 1rem;
						font-weight: 700;
						line-height: 1.56;
						margin: 0 24px 0 0;
            @media screen and (max-width: $tab){
              margin: 0 0 8px;
              text-align: center;
              br{
                display: none;
              }
            }
					}
					img{
						width: 320px;
            @media screen and (max-width: $tab){
              width: 100%;
            }
					}
				}
			}
		}
		
		.alt{
			text-align: center;
			margin: 48px 0 0;
			@media screen and (max-width: $phone){
				margin: 24px $sp-side 0;	
				text-align: justify;
			}
			a{
				color: $hard;
				text-decoration: underline;
			}
		}
	}
	
	.works{
		@include tex();
		&__flex{
			@include flex();
			align-items: center;
			@media screen and (max-width: $phone){
				display: block;
			}
			&__title{
				width: 340px;
				text-align: center;
				@media screen and (max-width: $tab){
					width: 300px;	
				}
				@media screen and (max-width: $phone){
					width: auto;
					margin: 0 $sp-side;
					padding: 40px 0 0;
				}
				h2{
					@include min();
					font-size: 1.12rem;
					font-weight: 700;
					margin: 0 0 16px;
					@media screen and (max-width: $phone){
						font-size: .88rem;
						margin: 0 0 12px;
					}
					&:before{
						@include min();
						font-weight: 700;
						content: attr(data-eng);
						font-size: 3.1rem;
						margin: 0 0 4px;
						line-height: 1;
						display: block;
						@media screen and (max-width: $phone){
							font-size: 2rem;	
						}
					}
				}
				.btnarea{
					@media screen and (max-width: $phone){
						margin-top: 20px;
					}
					a{
						display: block;
					}
				}
			}
			&__pics{
				width: calc(100% - 340px);
				transform: translateX(40px);
				@media screen and (max-width: $tab){
					width: calc(100% - 300px);	
				}
				@media screen and (max-width: $phone){
					width: calc(100% + #{$sp-side*2});
					transform: translateX(#{-$sp-side});
					margin: 32px 0 0;
					padding: 0 0 40px;
				}
				.swiper-wrapper{
					transition-timing-function: $ease;
				}
				.item{
					width: auto !important;
					margin: 0 2px 0 0;
					img{
						width: auto;
						height: 440px;
						@media screen and (max-width: $phone){
							height: 56vw;	
						}
					}
				}
			}
		}
	}
	
	.blog{
		.list{
			@include flex();
			justify-content: flex-start;
			margin: 0 0 0 -24px;
			@media screen and (max-width: $phone){
				margin: 0;
				display: block;
			}
			&__item{
				width: 50%;
				padding: 0 0 0 24px;
				margin: 24px 0 0;
				@include flex();
				@media screen and (max-width: $phone){
					width: auto;
					padding: 0;
					margin: 0 0 16px;
					&:last-child{
						margin: 0;
					}
				}
				&:nth-child(-n+2){
					margin-top: 0;
				}
				&__box{
					@include tex();
					padding: 20px;
					@include flex();
					width: 100%;
					height: auto;
					position: relative;
					@media screen and (max-width: $phone){
						padding: 15px;
						width: 100%;
					}
					&:after{
						content: '';
						width: 0;
						height: 0;
						border-style: solid;
						border-width: 0 0 8px 8px;
						border-color: transparent transparent $hard transparent;
						position: absolute;
						right: 8px;
						bottom: 8px;
					}
					&__pic{
						width: 120px;
						height: 120px;
						overflow: hidden;
						@media screen and (max-width: $phone){
							width: 80px;
							height: 80px;
						}
						img{
							width: 100%;
							height: 100%;
							object-fit: cover;
							object-position: center;
						}
					}
					&__data{
						width: calc(100% - 120px);
						padding: 0 0 0 20px;
						@media screen and (max-width: $phone){
							width: calc(100% - 80px);
							padding: 0 0 0 15px;
						}
						time{
							font-size: .8rem;
							padding: 3px 0;
							display: block;
							width: 110px;
							text-align: center;
							background: $normal;
							color: #FFF;
						}
						h4{
							font-size: 1.12rem;
							font-weight: 700;
							margin: 10px 0 0;
							@media screen and (max-width: $phone){
								font-size: 1rem;
								line-height: 1.56;
								margin: 6px 0 0;
							}
						}
						p{
							margin: 6px 0 0;
							font-size: .8rem;
							@media screen and (max-width: $phone){
								display: none;
							}
						}
					}
				}
			}
		}
		.btn{
			position: absolute;
			top: 4px;
			right: 0;
			@media screen and (max-width: $phone){
				position: relative;
				top: 0;
				left: 0;
				margin: 24px 0 0;
			}
			a{
				font-size: .96rem;
				border: $normal 1px solid;
				color: $normal;
				padding: 6px 24px;
				display: block;
				@media screen and (max-width: $phone){
					padding: 11px 0;
					display: block;
					font-size: 1.12rem;
					text-align: center;
				}
				i{
					display: inline-block;
					margin: 0 8px 0 0;
				}
			}
		}
	}
	
	.news{
        position: relative;
        z-index: 2;
		@include tex();
		color: $normal;
		&.wrap{
			padding-top: 80px;
			padding-bottom: 80px;
			@media screen and (max-width: $phone){
				padding-top: 40px;
				padding-bottom: 40px;
			}
		}
		.display{
			&.flex{
				@include flex();
				@media screen and (max-width: $phone){
					display: block;	
				}
			}
		}
		.newsbox{
			width: 100%;
			@include flex();
			@media screen and (max-width: $phone){
				display: block;
			}
		}
        .leftside{
            width: 280px;
			@media screen and (max-width: $phone){
				width: auto;
				position: relative;
				margin: 0 0 20px;
			}
			h3{
				@include min();
				line-height: 1;
				font-size: 1rem;
				margin: 0 0 12px;
				font-weight: 700;
				&:before{
					content: attr(data-eng);
					font-weight: 700;
					font-size: 2.4rem;
					display: inline-block;
					margin: 0 24px 0 0;
					transform: translateX(-2px);
					@media screen and (max-width: $phone){
						font-size: 1.44rem;
						margin: 0 12px 0 0;
					}
				}
			}
			a{
				@include min();
				display: inline-block;
				font-weight: 700;
				color: $normal;
				font-size: 1.08rem;
				@media screen and (max-width: $phone){
					position: absolute;
					top: 0;
					right: 0;
				}
				&:hover{
					text-decoration: underline;
				}
				i{
					display: inline-block;
					font-size: .96rem;
					margin: 0 0 0 12px;
				}
			}
        }
        ol{
            width: calc(100% - 280px);
            height: 132px;
            padding: 0 40px 0 0;
            overflow: auto;
			@media screen and (max-width: $tab){
				height: 150px;	
			}
			@media screen and (max-width: $phone){
				width: auto;
				padding: 0;
			}
            li{
                border-bottom: rgba($normal,.08) 1px solid;
                padding: 0 0 24px;
                margin: 0 0 24px;
				@media screen and (max-width: $tab){
					padding: 0 0 15px;
					margin: 0 0 15px;
				}
                &:last-child{
                    margin: 0;
                }
                a{
                    @include flex();
                    align-items: center;
                    color: $normal;
					@media screen and (max-width: $tab){
						display: block;	
					}
                    &:hover{
                        h4{
                            text-decoration: underline;
                        }
                    }
                }
                span.time{
                    width: 14%;
                    font-size: .88rem;
                    background: $hard;
                    color: #FFF;
                    padding: 2px 0;
                    text-align: center;
					@media screen and (max-width: $tab){
						width: 100px;
						display: inline-block;
					}
                }
                h4{
                    width: 86%;
                    font-size: 1.12rem;
                    padding: 0 0 0 32px;
					@media screen and (max-width: $tab){
						width: auto;
						font-size: 1rem;
						padding: 6px 0 0;
					}
                }
            }
        }
    }
	
	.kussion{
		position: relative;
		overflow: hidden;
		@include tex();
		&__bg{
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			@media screen and (max-width: $phone){
				transform: translate(0) !important;
			}
			&:before,
			&:after{
				content: '';
				width: 100%;
				height: 100%;
				position: absolute;
				top: 0;
				left: 0;
				z-index: 1;
				transform: scale(1.2);
			}
			&:before{
				background: url(/images/index/kussion.jpg) no-repeat center / cover;
			}
			&:after{
				background: #000;
				opacity: .56;
				@media screen and (max-width: $phone){
					opacity: .72;	
				}
			}
		}
		&__data{
			text-align: center;
			position: relative;
			z-index: 2;
			color: rgba(#FFF,.88);
			h3{
				@include min();
				font-size: 1.12rem;
				line-height: 1;
				margin: 0 0 24px;
				@media screen and (max-width: $phone){
					font-size: .88rem;	
				}
				&:before{
					content: attr(data-eng);
					font-size: 2.88rem;
					display: block;
					margin: 0 0 12px;
					@media screen and (max-width: $phone){
						font-size: 2rem;	
					}
				}
			}
			.context{
				@include min();
				font-size: 1.08rem;
				@media screen and (max-width: $phone){
					font-size: 1rem;
					margin: 0 $sp-side;
				}
			}
		}
	}
}