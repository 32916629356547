@charset "utf-8";

@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Dancing+Script:wght@600&display=swap');

@import "module/reset";
@import "module/module";

//USE ASSETS
@import "plugin/swiper";

/* BASE COLOR
------------------------------------------------------------*/
$normal:#282020;
$link:$normal;
$border:#e8e8e8;

$orange: #eec28d;
$green: #c4d8a2;
$yellow: #f8e594;
$hard: #ad8c0e;
$gray: #f3f3f4;

$ease: cubic-bezier(0.65, 0, 0.35, 1);

/* SITE SETTING
------------------------------------------------------------*/
$width: 1120px;
$tab: $width - 1px;
$phone: 769px;

$side: 40px;
$sp-side: 16px;

:root{
	font-size: 14.5px;
	@media screen and (max-width: $tab){
		font-size: 14px;	
	}
	@media screen and (max-width: $phone){
		font-size: 13.5px;	
	}
}

@media screen and (min-width: $width){
	@include default(
		$width, //サイト幅
		1rem, //フォントサイズ
		$normal, //フォントカラー
		$link, //リンクカラー
		0, //パディング
		#FFF //ボディ背景
	);
}

@media screen and (max-width: $tab){
	@include default(
		auto, //サイト幅
		1rem, //フォントサイズ
		$normal, //フォントカラー
		$link, //リンクカラー
        0, //パディング
		#FFF //ボディ背景
	);
}

@media screen and (max-width: $phone){
	@include default(
		auto, //サイト幅
		1rem, //フォントサイズ
		$normal, //フォントカラー
		$link, //リンクカラー
        0, //パディング
		#FFF //ボディ背景
	);
}

@mixin tex(){
	background-image: url(/images/common/bg_tex_2x.png);
	background-size: 256px;
}
@mixin ami($color){
	background-image: url('data:image/svg+xml;utf8,<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 15 15"><rect x="0" y="0" width="3" height="3" fill="#{$color}" stroke-width="0" /></svg>');
}

@import "common/header";
@import "common/footer";
@import "common/breadcrumb";
@import "page/index";
@import "page/firstview";
@import "page/about";
@import "page/flow";
@import "page/faq";
@import "page/contact";
@import "page/plan";
@import "page/works";
@import "page/wire";
@import "page/blog";
@import "page/news";
@import "page/staff";
@import "page/edit";

#site-wrapper,#site-footer{

}

.site-module{
	&.wrap{
		padding: 120px $side;
		@media screen and (max-width: $tab){
			padding: 72px $side;	
		}
		@media screen and (max-width: $phone){
			padding: 40px $sp-side;
		}
		&.mid{
			padding: 96px $side;
			@media screen and (max-width: $tab){
				padding: 56px $side;	
			}
			@media screen and (max-width: $phone){
				padding: 32px $sp-side;
			}
		}
		&.no-top{
			padding-top: 0;
		}
		&.no-bottom{
			padding-bottom: 0;
		}
		&.no-left{
			padding-left: 0;
		}
		&.no-right{
			padding-right: 0;
		}
		&.bg{
			@include tex();
		}
	}
	h2,
	h3,
	h4{
		&.large{
            @include min();
            font-size: 1.12rem;
            font-weight: 700;
            margin: 0 0 24px;
			text-align: center;
			@media screen and (max-width: $phone){
				font-size: .88rem;	
			}
            &:before{
                @include min();
                font-weight: 700;
                content: attr(data-eng);
                font-size: 3.1rem;
				margin: 0 0 4px;
                line-height: 1;
                display: block;
				@media screen and (max-width: $phone){
				font-size: 2rem;	
				}
            }
		}
	}
    h2,h3,h4{
        &.borders{
            border-bottom: $border 1px solid;
            position: relative;
            padding: 12px;
            padding-left: 20px;
            font-size: 1.64rem;
            line-height: 1;
            font-weight: 700;
            margin: 0 0 24px;
			@media screen and (max-width: $phone){
				margin: 0 0 15px;
				font-size: 1.24rem;
				padding-left: 18px;
			}
            &:after{
                content: '';
                width: 5px;
                height: 70%;
                background: $hard;
                border-radius: 4px;
                display: block;
                position: absolute;
                top: 15%;
                left: 0;
            }
			small{
				font-size: .96rem;
				display: inline-block;
				margin: 0 0 0 12px;
				@media screen and (max-width: $phone){
					font-size: .88rem;	
				}
			}
        }
	}
	.tablestyle{
		border: $border 1px solid;
		width: 100%;
		border-collapse: collapse;
		@media screen and (max-width: $phone){
			display: block;
			border-bottom: none;
		}
		tbody{
			@media screen and (max-width: $phone){
				display: block;	
			}
			tr{
				border-bottom: $border 1px solid;
				@media screen and (max-width: $phone){
					display: block;
				}
				td,
				th{
					padding: 32px;
					vertical-align: middle;
					text-align: left;
					@media screen and (max-width: $phone){
						display: block;
						padding: 12px $sp-side;
					}
				}
				th{
					width: 20%;
					min-width: 200px;
					border-right: $border 1px solid;
					background: $gray;
					@media screen and (max-width: $phone){
						width: auto;	
					}
				}
				td{
					a{
						color: $normal;
						display: inline-block;
						text-decoration: underline;
						&:hover{
							color: $hard;
						}
						i{
							display: inline-block;
							margin: 0 0 0 8px;
							color: $hard;
						}
					}
				}
			}
		}
	}
	.btnarea{
		margin: 32px 0 0;
		&.center{
			text-align: center;
		}
		a{
			display: inline-block;
			vertical-align: bottom;
			position: relative;
			border: $normal 1px solid;
			background: $normal;
			color: #FFF;
			font-size: 1.2rem;
			padding: 11px 72px;
			@media screen and (max-width: $tab){
				padding: 11px 56px;	
			}
			@media screen and (max-width: $phone){
				display: block;
				padding: 11px 32px;
				text-align: center;
        margin: 0 0 8px;
        &:last-of-type{
          margin-bottom: 0;
        }
			}
			&.block{
				padding: 10px 0;
				display: block;
				text-align: center;
			}
			&:after{
				content: "\f054";
				font-family: "Font Awesome 5 Free";
				font-weight: 900;
				position: absolute;
				top: 50%;
				right: 15px;
				transform: translateY(-50%);
				font-size: .72rem;
			}
			&:hover{
				background: $hard;
				border-color: $hard;
			}
		}
	}
	.gaikoDocument{
		background: $gray;
		padding: 40px;
		border-radius: 6px;
		&__title{
			font-size: 1.56rem;
			line-height: 1;
			font-weight: 700;
			margin: 0 0 16px;
		}
		a{
			border-color: $normal;
			border-width: 2px;
			border-radius: 999px;
			color: $normal;
			background: #FFF;
			&:hover{
				color: $hard;
				border-color: $hard;
				background: #FFF;
			}
		}
	}
	.pager{
		margin: 48px 0 0;
		@include flex();
		justify-content: center;
		align-items: center;
		@media screen and (max-width: $phone){
			margin: 24px 0 0;	
		}
		a,
		span{
			$size: 36px;
			width: $size;
			height: $size;
			background: $gray;
			border: $gray 2px solid;
			border-radius: 50%;
			font-size: 1.12rem;
			@include flex();
			justify-content: center;
			align-items: center;
			margin: 0 5px;
			color: $normal;
			transition: all 120ms $ease;
		}
		a{
			&:hover{
				border-color: $hard;
				color: $hard;
				transform: scale(1.1);
			}
		}
		span{
			background: $hard;
			border-color: $hard;
			color: #FFF;
		}
	}

}

.site-clum{
	@include flex();
	align-items: flex-start;
	@media screen and (max-width: $tab){
		display: block;	
	}
	.sidebar{
		width: 280px;
		order: 1;
		position: sticky;
		top: 80px;
		@media screen and (max-width: $tab){
			position: relative;
			top: 0;
			width: auto;
			margin: 40px 0 0;
		}
		&__box{
			margin: 0 0 48px;
			.btnarea{
				margin: 20px 0 0;
			}
			&:last-of-type{
				margin: 0;
			}
			&__list{
				li{
					margin: 0 0 16px;
					padding: 0 0 16px;
					border-bottom: $border 1px solid;
					&:last-child{
						margin: 0;
						padding: 0;
						border: none;
					}
					a{
						@include flex();
						figure{
							width: 70px;
							height: 70px;
							img{
								width: 100%;
								height: 100%;
								object-fit: cover;
								object-position: center;
							}
						}
						dl{
							width: calc(100% - 70px);
							padding: 0 0 0 16px;
							line-height: 1.56;
							dt{
								font-size: .92rem;
								font-weight: 700;
								margin: 6px 0 0;
							}
							dd{
								background: darken($gray, 2%);
								padding: 4px 12px;
								font-size: .8rem;
								width: 88px;
								text-align: center;
							}
						}
					}
				}
			}
			&__cate{
				@include flex();
				justify-content: flex-start;
				li{
					margin: 0 8px 8px 0;
					a{
						display: block;
						background: $gray;
						color: $normal;
						padding: 0 20px;
						border-radius: 4px;
						&:hover{
							background: $hard;
							color: #FFF;
						}
					}
				}
			}
		}
	}
	.container{
		width: calc(100% - 280px);
		padding: 0 0 0 56px;
		order: 2;
		@media screen and (max-width: $tab){
			width: auto;
			padding: 0;
		}
	}
}

.site-company{
	.maker{
		@include flex();
		justify-content: flex-start;
		margin: 0 0 0 -16px;
		li{
			width: 33.3%;
			padding: 0 0 0 16px;
			font-size: .96rem;
			@media screen and (max-width: $tab){
				width: 50%;
			}
			@media screen and (max-width: $phone){
				width: 100%;
				font-size: 1rem;
			}
		}
	}
}


.site-404{
	.message{
		text-align: center;
		dt{
			font-weight: 700;
			font-size: 1.24rem;
			margin: 0 0 12px;
		}
	}
}

#sidebtn{
    position: fixed;
    top: 50%;
    right: 0;
    transform: translateY(-50%) translateX(100%);
    z-index: 1000;
    display: block;
    -webkit-writing-mode: vertical-rl;
    -moz-writing-mode: vertical-rl;
    -ms-writing-mode: tb-rl;
    writing-mode: vertical-rl;
    padding: 32px 8px;
    border: $normal 2px solid;
    background: $normal;
    color: #FFF;
    font-size: 1.04rem;
    border-radius: 4px 0 0 4px;
	transition: transform 400ms $ease;
	@media screen and (max-width: $phone){
		display: none;
	}
	&.active{
		transform: translateY(-50%) translateX(0);
	}
	i{
		display: inline-block;
		margin: 0 0 8px;
	}
}

.scale-enter-active, .scale-leave-active {
	transition: 400ms $ease;
}
.scale-enter, .scale-leave-to {
	opacity: 0;
	transform: scale(1.08);
}


// USE DEMO
.demo{
	background-color: $gray;
	background-position: top center;
	background-repeat: no-repeat;
	background-size: 1440px;
	&__view{
		width: 1120px;
		margin: 0 auto;
		overflow: hidden;
		img{
			width: 1440px;
			height: auto;
			opacity: 0;
		}
	}
}

.grecaptcha-badge{
	z-index: 100 !important;
}