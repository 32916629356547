#site-header{
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	padding: 16px $side;
	@include flex();
	align-items: center;
	justify-content: flex-end;
	z-index: 1000;
	background: #FFF;
	transition: padding 300ms $ease , box-shadow 300ms $ease;
	@media screen and (max-width: $tab){
		z-index: 9999;
		background: none;
	}
  a{
    color: $normal;
		transition: color 300ms $ease;
  }
	&:before{
		content: '';
		position: absolute;
		width: 100%;
		height: 0;
		background: #FFF;
		top: 0;
		left: 0;
		transition: height 300ms $ease;
	}
	> * {
		z-index: 2;
	}
	&.active{
		@media screen and (min-width: calc(#{$tab} + 1px)){
			padding: 12px $side;
			&:before{
				height: 100%;
			}
			.logo{
				width: 112px;
				padding: 16px;
				border-radius: 8px;
				top: 0;
				left: $side;
			}
			.gnavi{
				ul{
					li{
						a{
							&:before{
								bottom: -12px;
							}
						}
					}
				}
			}
		}
	}
	.logo{
		width: 144px;
		padding: 28px;
		background: #FFF;
		position: absolute;
		border-radius: 0 0 8px 0;
		top: 0;
		left: 0;
		transition: all 300ms $ease;
		@media screen and (max-width: $tab){
			width: 100px;
			height: 100px;
			padding: 16px;
			border-radius: 0;
			z-index: 100;
		}
		@media screen and (max-width: $phone){
			width: 64px;
			height: 64px;
			padding: 8px;
		}
		img{
			width: 100%;
		}
	}
	.opener{
		display: none;
		@media screen and (max-width: $tab){
			position: absolute;
			top: 0;
			right: 0;
			background: $hard;
			width: 100px;
			height: 100px;
			@include flex();
			align-items: center;
			justify-content: center;
			cursor: pointer;
			z-index: 100;
			@media screen and (max-width: $phone){
				width: 64px;
				height: 64px;
				right: 0;
			}
			&:hover{
				background: lighten($hard, 4%);
			}
			&.close{
				background: $normal;
			}
			&__menu{
				width: 44px;
				@media screen and (max-width: $phone){
					width: auto;	
				}
				&__icon{
					width: 100%;
					height: 12px;
					display: block;
					position: relative;
					@media screen and (max-width: $phone){
						width: 22px;
						height: 8px;
						margin: 0 auto;
					}
					&:before,
					&:after{
						content: '';
						width: 100%;
						height: 1px;
						background: #FFF;
						position: absolute;
						top: 0;
						left: 0;
					}
					&:after{
						top: auto;
						bottom: 0;
					}
				}
				&__txt{
					@include min();
					text-align: center;
					color: #FFF;
					font-size: .88rem;
					line-height: 1;
					white-space: nowrap;
					letter-spacing: .12em;
					margin: 12px 0 0;
					display: block;
					&:nth-of-type(2){
						display: none;
					}
					@media screen and (max-width: $phone){
						margin: 10px 0 0;
						font-size: .8rem;
					}
				}
			}
		}
	}
	.gnavi{
		position: absolute;
		top: 50%;
		left: calc(50% - 40px);
		min-width: 820px;
		@media screen and (min-width: calc(#{$tab} + 1px)){
			transform: translate(-50%, -50%);
			display: block !important;
		}
		@media screen and (max-width: $tab){
			min-width: 1px;
			position: fixed;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background: #FFF;
			@include flex();
			align-items: center;
			justify-content: center;
			visibility: hidden;
			&.active{
				visibility: visible;
			}
			a{
				color: $normal;
			}
		}
		ul{
			@include flex();
			justify-content: flex-start;
			@media screen and (max-width: $tab){
        padding: 12.5vmax 5.6vw;
        max-height: 100vh;
        overflow: auto;
        width: 100%;
        justify-content: space-between;
			}
      @media screen and (max-width: $phone){
        padding-top: 16.6vh;
      }
			li{
				font-size: .96rem;
				margin: 0 0 0 36px;
				&.spv{
					display: none;
					@media screen and (max-width: $tab){
						@include flex();
            justify-content: center;
					}
				}
				@media screen and (max-width: $tab){
					@include min();
          width: 50%;
					margin: 2.5vh 0;
					font-size: 1.56rem;
					text-align: center;
          @include flex();
          justify-content: center;
				}
        @media screen and (max-width: $phone){
          font-size: 1.2rem;
        }
				&:first-child{
					margin-left: 0;
				}
				a{
					letter-spacing: .08em;
					position: relative;
					display: block;
          @media screen and (max-width: $tab){
            display: inline-block;
          }
					&:before{
						content: '';
						width: 0;
						height: 3px;
						position: absolute;
						left: 50%;
						bottom: -16px;
						background: $hard;
						transform: translateX(-50%);
						transition: width 120ms $ease;
						@media screen and (max-width: $tab){
							display: none;
						}
					}
					&:after{
						content: attr(data-eng);
						display: block;
						text-transform: uppercase;
						font-size: .72rem;
						text-align: center;
						color: $hard;
					}
					&:hover{
						&:before{
							width: 48px;
						}
					}
				}
			}
		}
	}
	.contact{
		position: relative;
    background: $hard;
		border: $hard 2px solid;
    color: #FFF;
		padding: 7px 40px;
		display: inline-block;
		transition: all 300ms $ease;
		@media screen and (max-width: $tab){
			display: none;
		}
    &:hover{
      border-color: $hard;
      background: #FFF;
      color: $hard;
    }
		i{
			display: inline-block;
			margin: 0 10px 0 0;
		}
	}
}